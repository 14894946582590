import React from 'react';
import PropTypes from 'prop-types';
import { NextArrow } from 'site-modules/shared/components/carousel/carousel';

export function UsurpInventoryCardCarouselArrowRight(props) {
  const { currentSlide, slideCount, ...rest } = props;

  return (
    <NextArrow
      disabled={currentSlide === slideCount - 1}
      currentSlide={currentSlide}
      slideCount={slideCount}
      {...rest}
    />
  );
}

UsurpInventoryCardCarouselArrowRight.propTypes = {
  currentSlide: PropTypes.number,
  slideCount: PropTypes.number,
};

UsurpInventoryCardCarouselArrowRight.defaultProps = {
  currentSlide: 0,
  slideCount: 1,
};
