import React from 'react';
import PropTypes from 'prop-types';
// Models
import { InventoryEntities } from 'client/data/models/inventory';
import { VisitorEntities } from 'client/data/models/visitor';
// Constants
import { SrpCardMoreInfo } from 'site-modules/shared/components/inventory/srp-card/srp-card-more-info';
import { ViewMore } from 'site-modules/shared/components/inventory/view-more/view-more';

export function UsurpCardViewMore({ vehicle, vehicleName, visitorLocation, isLeaseCardWithLeasePayment }) {
  return (
    <ViewMore
      collapsedSummary={<i className="icon-info size-14 text-cool-gray-50" aria-hidden />}
      expandedSummary={<span className="size-14 text-cool-gray-50">Close</span>}
      ariaLabel={`Additional information about ${vehicleName}`}
      trackingId="view_more_info"
      className="mt-1 justify-content-end"
      noIcon
    >
      <SrpCardMoreInfo
        vinData={vehicle}
        visitorCity={visitorLocation.city}
        visitorStateCode={visitorLocation.stateCode}
        displayLeaseInfo={isLeaseCardWithLeasePayment}
        isSrpExpanded
      />
    </ViewMore>
  );
}

UsurpCardViewMore.propTypes = {
  vehicle: InventoryEntities.InventoryVin.isRequired,
  vehicleName: PropTypes.string,
  visitorLocation: VisitorEntities.Location,
  isLeaseCardWithLeasePayment: PropTypes.bool,
};

UsurpCardViewMore.defaultProps = {
  vehicleName: '',
  visitorLocation: '',
  isLeaseCardWithLeasePayment: false,
};
