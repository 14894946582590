import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { formatPriceString } from 'site-modules/shared/utils/price-utils';
import { isNew } from 'site-modules/shared/utils/inventory-utils/is-new';
import { InventoryEntities } from 'client/data/models/inventory';
import { DeliveryTooltip } from 'site-modules/shared/components/delivery-tooltip/delivery-tooltip';

function getPrice(prices, displayLeaseMonthlyPayment, displayLoanMonthlyPayment) {
  if (displayLeaseMonthlyPayment) {
    return get(prices, 'estimateLeasePromise.monthlyPayment', 0);
  }

  if (displayLoanMonthlyPayment) {
    return get(prices, 'loan.payment', 0);
  }

  return get(prices, 'displayPrice', 0);
}

export function PriceLabel({
  displayLeaseMonthlyPayment,
  displayLoanMonthlyPayment,
  displayMSRP,
  type,
  prices,
  deliveryFee,
  includeDeliveryFee,
}) {
  const priceToShow = getPrice(prices, displayLeaseMonthlyPayment, displayLoanMonthlyPayment);
  const downPayment = get(prices, 'estimateLeasePromise.downPayment', 0);
  const displayPrice = get(prices, 'displayPrice', 0);
  const formattedSummaryPrice =
    includeDeliveryFee && deliveryFee ? formatPriceString(priceToShow + deliveryFee) : formatPriceString(priceToShow);

  return (
    <div>
      <span className="heading-3">{formattedSummaryPrice}</span>
      {includeDeliveryFee &&
        (deliveryFee >= 0 ? (
          <DeliveryTooltip price={priceToShow} deliveryFee={deliveryFee} className="ml-0_25" classNameWrapper="p-1" />
        ) : (
          <div className="small text-underline mb-0_5">Delivery Costs Unknown</div>
        ))}
      {displayMSRP && <div style={{ fontSize: '0.875rem', lineHeight: '1.25rem', letterSpacing: '0' }}>MSRP</div>}
      {displayLeaseMonthlyPayment && (
        <Fragment>
          <span className="text-nowrap size-12">/mo est.</span>
          <div className="size-12">{formatPriceString(downPayment)} down</div>
        </Fragment>
      )}
      {displayLoanMonthlyPayment && (
        <Fragment>
          <span className="text-nowrap size-12">/mo est.</span>
          <div className="size-12">
            {formatPriceString(displayPrice)} {isNew(type) ? 'MSRP' : 'Price'}
          </div>
        </Fragment>
      )}
    </div>
  );
}

PriceLabel.propTypes = {
  type: PropTypes.string.isRequired,
  prices: InventoryEntities.Prices.isRequired,
  displayLeaseMonthlyPayment: PropTypes.bool,
  displayLoanMonthlyPayment: PropTypes.bool,
  displayMSRP: PropTypes.bool,
  deliveryFee: PropTypes.number,
  includeDeliveryFee: PropTypes.bool,
};

PriceLabel.defaultProps = {
  displayLeaseMonthlyPayment: false,
  displayLoanMonthlyPayment: false,
  displayMSRP: false,
  deliveryFee: undefined,
  includeDeliveryFee: false,
};
