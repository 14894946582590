import { get } from 'lodash';

/**
 * Get features string from inventory item (E.g. AWD/4WD, Tire Pressure Warning, Audio and cruise controls on steering wheel, Stability Control, 6ft Bed)
 *
 * @param {Object} vinData Vehicle data.
 * @returns {String}
 */
export function getFeaturesString(vinData) {
  return get(vinData, 'vehicleInfo.partsInfo.cgf', [])
    .slice(0, 5)
    .map(feature => feature.name)
    .join(', ');
}
