import React from 'react';
import PropTypes from 'prop-types';
import { PrevArrow } from 'site-modules/shared/components/carousel/carousel';

export function UsurpInventoryCardCarouselArrowLeft(props) {
  const { currentSlide, ...rest } = props;

  return <PrevArrow disabled={currentSlide === 0} currentSlide={currentSlide} {...rest} />;
}

UsurpInventoryCardCarouselArrowLeft.propTypes = {
  currentSlide: PropTypes.number,
};

UsurpInventoryCardCarouselArrowLeft.defaultProps = {
  currentSlide: 0,
};
