import React, { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './clickable-card.scss';

export const ClickableCard = memo(props => {
  const { children, className, ...rest } = props;

  return (
    <div className={classNames('clickable-card pos-r', className)} {...rest}>
      {children}
    </div>
  );
});

ClickableCard.displayName = 'ClickableCard';

ClickableCard.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

ClickableCard.defaultProps = {
  className: undefined,
};
