import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get } from 'lodash';
import { InventoryEntities } from 'client/data/models/inventory';
import { Row, Col } from 'reactstrap';

import './recurrent-insights.scss';

export function RecurrentInsights({ vehicle, className }) {
  const rangeScoreDescriptor = get(vehicle, 'thirdPartyInfo.recurrentInsights.rangeScoreDescriptor');
  const expectedRangeMin = get(vehicle, 'thirdPartyInfo.recurrentInsights.expectedRange.min');
  const expectedRangeMax = get(vehicle, 'thirdPartyInfo.recurrentInsights.expectedRange.max');

  return (
    <Row className={classnames('recurrent-insights-srp bg-cool-gray-100 p-1', className)} noGutters>
      <Col xs={9} className="medium pl-0 text-cool-gray-30">
        <div className="mb-0_5">
          <span className="font-weight-bold">Est. Range:</span>{' '}
          {expectedRangeMin === expectedRangeMax
            ? `${expectedRangeMin}mi`
            : `${expectedRangeMin} - ${expectedRangeMax}mi`}
        </div>
        <div>
          <span className="font-weight-bold">Battery Range Score:</span>{' '}
          {rangeScoreDescriptor === 'Poor' && <i className="icon-warning2" aria-hidden />} {rangeScoreDescriptor}
        </div>
      </Col>
      <Col xs={3} className="p-0 d-flex align-items-end text-cool-gray-40">
        <i className="small text-right">Provided by Recurrent®</i>
      </Col>
    </Row>
  );
}

RecurrentInsights.propTypes = {
  vehicle: InventoryEntities.InventoryVin.isRequired,
  className: PropTypes.string,
};

RecurrentInsights.defaultProps = {
  className: null,
};
