import React from 'react';
import PropTypes from 'prop-types';
import { CardPhotoWatermark } from 'client/site-modules/shared/components/inventory/card-photo-watermark/card-photo-watermark';

import './srp-card-photo-overlay.scss';

export function SrpCardPhotoOverlay({ provider }) {
  return (
    <CardPhotoWatermark
      providerPhotoType={provider}
      classNames="photo-type disclaimer text-white rounded m-0_5 p-0_25"
      inlineStyles={{
        bottom: 0,
        position: 'absolute',
        right: 0,
        fontSize: '0.5625rem',
        lineHeight: '0.75rem',
        letterSpacing: 0,
      }}
    />
  );
}

SrpCardPhotoOverlay.propTypes = {
  provider: PropTypes.string,
};

SrpCardPhotoOverlay.defaultProps = {
  provider: '',
};
