import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop } from 'lodash';
import { SOURCES } from 'client/utils/image-helpers';
import { Image } from 'site-modules/shared/components/image/image';
import {
  getNoVehicleImage,
  getNoVehicleImageToDisplay,
} from 'site-modules/shared/utils/get-no-vehicle-image-to-display';
import { trackShowSilhouette } from 'site-modules/shared/utils/inventory/tracking';
import { TrackingConstant } from 'client/tracking/constant';
import { ClientSideRender } from 'site-modules/shared/components/client-side-render/client-side-render';

import './usurp-inventory-card-photo-image.scss';

export const UsurpInventoryCardPhotoImage = ({
  imageId,
  source,
  isNoImage,
  provider,
  fireTracking,
  isLoadingStateDisabled,
  vin,
  isDisablePhotoCarousel,
  clientSideRenderImage,
  lazyLoadImage,
  importance,
  bodyType,
  classNames,
}) => {
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const displayNoImg = hasError || isNoImage;
  const noVehicleImage = getNoVehicleImage(bodyType);
  const errorImage = getNoVehicleImageToDisplay(bodyType);

  const onError = () => {
    if (!hasError) {
      trackShowSilhouette(TrackingConstant.SHOW_SILHOUETTE_ERROR, vin, imageId);
      setHasError(true);
    }
  };
  const onLoad = () => {
    setIsLoading(false);
  };
  useEffect(() => {
    fireTracking({ photoUrl: imageId, provider });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const img = (
    <Fragment>
      <Image
        imageId={displayNoImg ? noVehicleImage : imageId}
        source={displayNoImg ? SOURCES.STATIC : source}
        onError={onError}
        onLoad={onLoad}
        errorImage={errorImage}
        className={`image ${displayNoImg ? 'no-image' : ''}`}
        lazy={lazyLoadImage}
        importance={importance}
      />
      {displayNoImg && (
        <div
          className="no-photo text-gray-darker font-weight-bold w-100 text-center"
          style={{ position: 'absolute', top: '83%' }}
        >
          Photos not available
        </div>
      )}
    </Fragment>
  );

  return (
    <figure className={classnames('usurp-inventory-card-photo-image pos-r m-0', classNames)}>
      {!isLoadingStateDisabled && isLoading && !displayNoImg && !isDisablePhotoCarousel && (
        <div className="loading-dots w-100 h-100 d-flex align-items-center justify-content-center">
          <span className="dot first" />
          <span className="dot second mx-1" />
          <span className="dot third" />
        </div>
      )}
      {clientSideRenderImage ? <ClientSideRender>{img}</ClientSideRender> : img}
    </figure>
  );
};
UsurpInventoryCardPhotoImage.propTypes = {
  imageId: PropTypes.string,
  source: PropTypes.string,
  isNoImage: PropTypes.bool,
  provider: PropTypes.string,
  fireTracking: PropTypes.func,
  isLoadingStateDisabled: PropTypes.bool,
  vin: PropTypes.string,
  isDisablePhotoCarousel: PropTypes.bool,
  clientSideRenderImage: PropTypes.bool,
  lazyLoadImage: PropTypes.bool,
  importance: PropTypes.string,
  bodyType: PropTypes.string,
  classNames: PropTypes.string,
};

UsurpInventoryCardPhotoImage.defaultProps = {
  imageId: '',
  source: '',
  isNoImage: false,
  provider: null,
  fireTracking: noop,
  isLoadingStateDisabled: false,
  vin: '',
  isDisablePhotoCarousel: false,
  clientSideRenderImage: false,
  lazyLoadImage: false,
  importance: null,
  bodyType: '',
  classNames: '',
};
