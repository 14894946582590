import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  getInventoryBadgeText,
  INVENTORY_BADGE_LABELS,
} from 'site-modules/shared/utils/inventory/usurp-inventory-card';

import './inventory-badge-srp.scss';

const INVENTORY_ICON_BADGES_MAP = {
  [INVENTORY_BADGE_LABELS.PRICE_DROP]: {
    icon: 'icon-arrow-down8',
    iconClassname: 'text-green-50 size-10',
    background: 'bg-green-100',
  },
  [INVENTORY_BADGE_LABELS.ARRIVING_SOON]: {
    icon: 'icon-clock',
    iconClassname: 'text-blue-50 size-10',
    background: 'bg-blue-100',
  },
  [INVENTORY_BADGE_LABELS.NEW_LISTING]: {
    icon: 'icon-sparkles',
    iconClassname: 'text-yellow-20 size-14',
    background: 'bg-yellow-100',
  },
};

export function InventoryBadgeSrp({ vinInfo, showInTransit }) {
  const text = getInventoryBadgeText(vinInfo, showInTransit);
  if (!text) return null;
  const { icon, iconClassname, background } = INVENTORY_ICON_BADGES_MAP[text];

  return (
    <span
      className={classnames(
        'inventory-badge-srp size-12 font-weight-medium text-cool-gray-30 px-0_5 py-0_25',
        background
      )}
      style={{ position: 'absolute' }}
    >
      {icon && <i className={classnames(icon, iconClassname, 'mr-0_25')} aria-hidden />}
      {text}
    </span>
  );
}

InventoryBadgeSrp.propTypes = {
  vinInfo: PropTypes.shape({}).isRequired,
  showInTransit: PropTypes.bool,
};

InventoryBadgeSrp.defaultProps = {
  showInTransit: false,
};
