export const CREATIVE_ID = 'suggested-filters-modal';
export const SURVEY_CREATIVE_ID = 'feedback-survey-semantic-inventory-search';
export const ERROR_SCREEN_CREATIVE_ID = 'error-state-semantic-inventory-search';
export const NO_FACETS_SCREEN_CREATIVE_ID = 'no-facets-semantic-inventory-search';
export const FOUND_VIN_SCREEN_CREATIVE_ID = 'vdp-found-semantic-inventory-search';
export const RADIUS_EXPANSION_CREATIVE_ID = 'radius-expansion-semantic-inventory-search';
export const NO_INVENTORY_MATCHES_CREATIVE_ID = 'no-matches-suggested-filters-modal';
export const SUGGESTED_FACETS_LLM_CREATIVE_ID = 'edm-entry-suggested-facets-llm';
export const APPRAISAL_VIN_LINK_CREATIVE_ID = 'appraisal-link-semantic-inventory-search';
export const DECODED_VIN_CREATIVE_ID = 'decoded-vin-semantic-inventory-search';
export const NO_RESULTS_VIN_CREATIVE_ID = 'no-result-vin-semantic-inventory-search';
export const RELATED_LINKS_CREATIVE_ID = 'edm-entry-related-links';
