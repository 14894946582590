import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get, isNull } from 'lodash';
import { InventoryEntities } from 'client/data/models/inventory';
import {
  DEAL_TYPES,
  DEFAULT_PRICE,
  DEAL_TYPE_ICON,
  TEXT_COLOR,
} from 'site-modules/shared/components/deal/deal-constants';
import { isTexas } from 'site-modules/shared/utils/inventory-utils/is-texas';
import { getSavingsText } from 'client/site-modules/shared/utils/inventory-utils/get-savings-text';
import { isUsed } from 'site-modules/shared/utils/inventory-utils/is-used';
import { isNew } from 'site-modules/shared/utils/inventory-utils/is-new';
import { PriceLabel } from 'site-modules/shared/components/usurp-inventory-card/price-label/price-label';
import { PriceLabelDealer } from 'site-modules/shared/components/usurp-inventory-card/price-label/price-label-dealer';

import './pricing-details-srp.scss';

export function PricingDetails({
  vehicle,
  displayBelowMarketCopy,
  displayLeaseMonthlyPayment,
  displayLoanMonthlyPayment,
  displayDealBadge,
  displayMSRP,
  includeDeliveryFee,
}) {
  const { type, prices } = vehicle;
  const displayPrice = get(prices, 'displayPrice', 0);
  const deliveryCost = get(vehicle, 'computedDisplayInfo.deliveryOptions.deliveryCost');
  const deliveryCode = get(vehicle, 'computedDisplayInfo.deliveryOptions.deliveryCode');
  const deliveryFee = deliveryCode === 'FREE' ? 0 : deliveryCost;
  const savings = get(vehicle, 'computedDisplayInfo.priceValidation.priceDiscount', 0);
  const dealType = get(vehicle, 'computedDisplayInfo.priceValidation.dealType', '');
  const leasePayment = get(prices, 'estimateLeasePromise.monthlyPayment', 0);
  const loanPayment = get(prices, 'loan.payment', 0);
  const dealerPrice = get(vehicle, 'computedDisplayInfo.transparentPricingCompliance.guaranteedPrice', 0);
  const priceStrikethrough = get(vehicle, 'computedDisplayInfo.priceStrikethrough', false);

  let priceToShow = displayPrice;

  if (displayLeaseMonthlyPayment) {
    priceToShow = leasePayment;
  }
  if (displayLoanMonthlyPayment) {
    priceToShow = loanPayment;
  }

  const stateCode = get(vehicle, 'dealerInfo.address.stateCode', '');
  const isSavingsPresent = !isNull(get(vehicle, 'computedDisplayInfo.priceValidation.priceDiscount', null));
  const isDealTypeBadgeEnabled = displayDealBadge && DEAL_TYPES.includes(dealType) && !isNew(type);

  return (
    <div className="pricing-details d-flex flex-column">
      {priceToShow ? (
        <Fragment>
          {!displayMSRP ? (
            <PriceLabel
              displayLeaseMonthlyPayment={displayLeaseMonthlyPayment}
              displayLoanMonthlyPayment={displayLoanMonthlyPayment}
              displayMSRP={displayMSRP}
              prices={prices}
              type={type}
              includeDeliveryFee={includeDeliveryFee}
              deliveryFee={deliveryFee}
            />
          ) : (
            <PriceLabelDealer
              msrpPrice={displayPrice}
              dealerPrice={dealerPrice}
              priceStrikethrough={priceStrikethrough}
            />
          )}
          {isDealTypeBadgeEnabled && (
            <div>
              <span className="font-weight-bold text-gray-darkest">
                <i
                  className={classnames('align-middle mr-0_75', DEAL_TYPE_ICON[dealType], TEXT_COLOR[dealType])}
                  style={{ width: '1em', height: '1em', display: 'inline-block' }}
                  aria-hidden
                />
                {dealType.toLowerCase()} price
              </span>
              {(displayBelowMarketCopy || (isSavingsPresent && !isTexas(stateCode))) &&
                !(displayLoanMonthlyPayment && isUsed(type)) && (
                  <div className="size-12">{getSavingsText(savings).toLowerCase()}</div>
                )}
            </div>
          )}
        </Fragment>
      ) : (
        <span className="text-gray-dark heading-3">{DEFAULT_PRICE}</span>
      )}
    </div>
  );
}

PricingDetails.propTypes = {
  vehicle: InventoryEntities.InventoryVin.isRequired,
  displayBelowMarketCopy: PropTypes.bool,
  displayLeaseMonthlyPayment: PropTypes.bool,
  displayLoanMonthlyPayment: PropTypes.bool,
  displayDealBadge: PropTypes.bool,
  displayMSRP: PropTypes.bool,
  includeDeliveryFee: PropTypes.bool,
};

PricingDetails.defaultProps = {
  displayBelowMarketCopy: false,
  displayLeaseMonthlyPayment: false,
  displayLoanMonthlyPayment: false,
  displayDealBadge: true,
  displayMSRP: false,
  includeDeliveryFee: false,
};
