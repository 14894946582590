import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'site-modules/shared/components/link/link';
import React from 'react';

import './clickable-card-link.scss';

export const ClickableCardLink = props => {
  const { children, className, linkComponent: LinkComponent, ...rest } = props;
  return (
    <LinkComponent className={classNames('clickable-card-link', className)} {...rest}>
      {children}
    </LinkComponent>
  );
};

ClickableCardLink.propTypes = {
  linkComponent: PropTypes.elementType,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

ClickableCardLink.defaultProps = {
  linkComponent: Link,
  className: undefined,
};
