import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { KeyPoint } from 'site-modules/shared/components/inventory/key-point/key-point';

export function UsurpCardKeyPoint({ tag: Tag, columnSizes, keyPoints, isGrayIcons, htmlTag }) {
  return keyPoints.map(point => (
    <Tag
      key={point.iconTitle}
      {...columnSizes}
      className="size-14 d-flex align-items-baseline mt-0_5"
      style={{ lineHeight: '1.125rem' }}
      tag={htmlTag}
    >
      <KeyPoint
        {...point}
        classes={classnames({
          'text-cool-gray-40': isGrayIcons,
          'text-blue-50': !isGrayIcons,
        })}
      />
    </Tag>
  ));
}

UsurpCardKeyPoint.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  columnSizes: PropTypes.shape({
    xs: PropTypes.number,
  }),
  keyPoints: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      iconTitle: PropTypes.string,
      content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      showFiveStar: PropTypes.bool,
    })
  ),
  isGrayIcons: PropTypes.bool,
  htmlTag: PropTypes.string,
};

UsurpCardKeyPoint.defaultProps = {
  tag: 'div',
  columnSizes: {},
  keyPoints: [],
  isGrayIcons: false,
  htmlTag: undefined,
};
