import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { PHOTO_TYPE } from 'client/engagement-handlers/inventory-engagement-handler/constants';

export function CardPhotoWatermark({ providerPhotoType, classNames, inlineStyles }) {
  if (!providerPhotoType) {
    return null;
  }

  return (
    <span className={classNames} style={inlineStyles}>
      {providerPhotoType === PHOTO_TYPE.EVOX && (
        <Fragment>
          Stock photo © eVox Productions<sup>**</sup>
        </Fragment>
      )}
      {[PHOTO_TYPE.OEM, PHOTO_TYPE.IZMO].includes(providerPhotoType) && (
        <Fragment>
          Stock photo<sup>**</sup>
        </Fragment>
      )}
    </span>
  );
}

CardPhotoWatermark.propTypes = {
  providerPhotoType: PropTypes.string,
  classNames: PropTypes.string.isRequired,
  inlineStyles: PropTypes.shape({}),
};

CardPhotoWatermark.defaultProps = {
  providerPhotoType: '',
  inlineStyles: null,
};
