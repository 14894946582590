import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { CarouselMetaContext } from 'site-modules/shared/components/usurp-inventory-card/usurp-inventory-card-carousel/carousel-meta-context';

const MAX_DOTS_NUMBER = 5;
const DOTS_NUMBER_BEFORE_CENTER = Math.floor(MAX_DOTS_NUMBER / 2);

export const Dots = memo(props => {
  const { currentSlide } = useContext(CarouselMetaContext);
  const { photos, dots, className, ...rest } = props;

  const stopSlidingDotInd = photos.length - DOTS_NUMBER_BEFORE_CENTER - 1;
  const offset = 10;
  let translate = 0;
  if (currentSlide >= stopSlidingDotInd) {
    translate = offset * (stopSlidingDotInd - DOTS_NUMBER_BEFORE_CENTER);
  } else if (currentSlide > DOTS_NUMBER_BEFORE_CENTER) {
    translate = offset * (currentSlide - DOTS_NUMBER_BEFORE_CENTER);
  }
  return (
    <div className={className} {...rest}>
      <div className="dots-wrapper" aria-hidden>
        <ul style={{ transform: `translateX(-${translate}px)` }}>{dots}</ul>
      </div>
    </div>
  );
});

Dots.propTypes = {
  photos: PropTypes.arrayOf(PropTypes.string).isRequired,
  dots: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Dots.defaultProps = {
  className: '',
};

Dots.displayName = 'Dots';
