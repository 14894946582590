import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { CarouselMetaContext } from 'site-modules/shared/components/usurp-inventory-card/usurp-inventory-card-carousel/carousel-meta-context';

export const CustomPaging = memo(props => {
  const { index, photos } = props;
  const { currentSlide } = useContext(CarouselMetaContext);
  const isLast = currentSlide === photos.length;
  const isActiveDot = index === currentSlide;
  return (
    <span
      className={classnames('custom-paging', {
        'small-dot': !isActiveDot && !isLast && (index < currentSlide - 1 || index > currentSlide + 1),
      })}
    />
  );
});

CustomPaging.propTypes = {
  index: PropTypes.number.isRequired,
  photos: PropTypes.arrayOf(PropTypes.string).isRequired,
};

CustomPaging.displayName = 'CustomPaging';
